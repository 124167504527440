import React from 'react';
import PropTypes from 'prop-types';
import styles from './TaskButtons.module.css';
import classNames from 'classnames';
import { Tasks } from '../../../shared-logic/enums';

const TaskButtons = ({
  onTaskSelection,
  disableLower,
  disableUpper,
  disableXRay,
  conflictLower,
  conflictUpper,
  conflictXRay,
  selectedTask,
}) => {
  const buttonStyle = (task, conflicted, disabled) => {
    if (disabled) {
      return styles.taskButton;
    }
    const isSelectedTask = task === selectedTask;
    return classNames(styles.taskButton, {
      [styles.selectedTask]: isSelectedTask,
      [styles.pressable]: !isSelectedTask,
      [styles.consistentTask]: !conflicted,
      [styles.inconsistentTask]: conflicted,
    });
  };

  const handleTaskSelection = (target, selectedTask) => {
    target.blur();
    onTaskSelection(selectedTask);
  };

  return (
    <span>
      <button
        className={buttonStyle(Tasks.LOWER, conflictLower, disableLower)}
        disabled={disableLower}
        onClick={(e) => handleTaskSelection(e.target, Tasks.LOWER)}
      >
        LOWER
      </button>
      <button
        className={buttonStyle(Tasks.UPPER, conflictUpper, disableUpper)}
        disabled={disableUpper}
        onClick={(e) => handleTaskSelection(e.target, Tasks.UPPER)}
      >
        UPPER
      </button>
      <button
        className={buttonStyle(Tasks.XRAY, conflictXRay, disableXRay)}
        disabled={disableXRay}
        onClick={(e) => {
          handleTaskSelection(e.target, Tasks.XRAY);
        }}
      >
        X-RAY
      </button>
    </span>
  );
};

TaskButtons.propTypes = {
  /**
   * onTaskSelection function
   */
  onTaskSelection: PropTypes.func,
  /**
   * Should disable lower task button
   */
  disableLower: PropTypes.bool,
  /**
   * Should disable upper task button
   */
  disableUpper: PropTypes.bool,
  /**
   * Should disable x-ray task button
   */
  disableXRay: PropTypes.bool,
  /**
   * Selected task
   */
  selectedTask: PropTypes.oneOf([Tasks.LOWER, Tasks.UPPER, Tasks.XRAY]),
};

export default TaskButtons;
